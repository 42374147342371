<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">类型选择：</span>
                    <el-select v-model="holdValue" placeholder="请选择" @change="(pageNum = 1), getTrainingList()">
                        <el-option v-for="item in hold" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="col">
                    <span class="type">举办场馆：：</span>
                    <el-select v-model="venueValue" placeholder="请选择" @change="(pageNum = 1), getTrainingList()">
                        <el-option v-for="item in venue" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="col">
                    <span class="type">智能排序：</span>
                    <el-select v-model="intelligentValue" placeholder="请选择" @change="(pageNum = 1), getTrainingList()">
                        <el-option v-for="item in intelligent" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="right">
                <!--                <img src="@/assets/images/Frame-23.png" alt="" />-->
                <el-input class="sq-user-search" prefix-icon="el-icon-search" placeholder="请输入内容" v-model="search" clearable></el-input>
            </div>
        </div>
        <div class="good-list-global">
            <goodListGlobal v-for="(item, index) in list" :key="index" :item="item" @click.native="$router.push({ path: '/courseTrainingDetail', query: { id: item.id } })"></goodListGlobal>
        </div>

        <el-pagination class="pagination" background :page-size="pageSize" :page-count="pageNum" layout="prev, pager, next,jumper" :total="total"></el-pagination>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getLibraryList, getTrainingList } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '活动',
                },
                {
                    title: '课程培训',
                    to: { path: '/my', query: { tabs: '0' } },
                },
            ],
            search: null,
            holdValue: null,
            hold: [
                {
                    label: '全部',
                    value: null,
                },
                {
                    label: '演出',
                    value: 0,
                },
                {
                    label: '讲座',
                    value: 1,
                },
                {
                    label: '展览',
                    value: 2,
                },
                {
                    label: '会议',
                    value: 3,
                },
            ],
            intelligentValue: null,
            intelligent: [
                {
                    label: '默认',
                    value: null,
                },
                {
                    label: '即将开始',
                    value: 0,
                },
                {
                    label: '最新发布',
                    value: 1,
                },
                {
                    label: '人气',
                    value: 2,
                },
            ],
            venue: [],
            venueValue: null,
            value: '',
            pageNum: 1,
            pageSize: 12,
            list: [],
            time: '',
			total: 0,
        };
    },
    props: {},

    // 方法集合
    methods: {
        getTrainingList() {
            let data = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: this.holdValue,
                intelligentSort: this.intelligentValue,
                libraryName: this.venueValue,
                name: this.search,
                library: this.$route.query.id,
                libraryId: this.venueValue?this.venueValue:localStorage.getItem('id'),
            };
            getTrainingList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        // 获取场馆--筛选用
        getLibraryLists() {
            getLibraryList({ id: localStorage.getItem('id'), libType: this.holdValue }).then((res) => {
                let arr = res.data.libraryList.map((item) => {
                    return {
                        label: item.libName,
                        value: item.id,
                    };
                });
                arr.unshift({
                    label: '全部',
                    value: null,
                });
                this.venue = arr;
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {
        search() {
            clearTimeout(this.time);
            this.time = setTimeout(() => {
                this.getTrainingList();
            }, 500);
        },
    },
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getTrainingList();
        this.getLibraryLists();
		this.$hao.getDictDataByType("sys_activity_type").then((res) => {
				    let arr = res.data.map(ele => {
						return {label: ele.dictLabel, value: ele.dictValue};
					});
					arr.unshift({
					    label: '全部',
					    value: '',
					});
					this.hold = arr;
				});
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.box {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            .col {
                display: flex;
                align-items: center;
                margin-right: 24px;
                span {
                    font-size: 13px;
                    color: #333333;
                }
                .el-select {
                    width: 160px;
                }
                ::v-deep .el-input__inner {
                    border: 0;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 24px;
            }
            ::v-deep .el-input__inner,
            ::v-deep .el-input {
                border-radius: 20px;
                width: 240px;
                height: 30px;
            }
            ::v-deep .el-input__prefix {
                margin-top: -3px;
                //i {
                //    height: 30px;
                //}
            }
        }
    }
    .good-list-global {
        //一行三列
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px 20px;
        padding-top: 20px;
    }
    .pagination {
        margin: 20px auto;
    }
}
</style>
